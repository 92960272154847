import React, { FC, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  CircularProgress,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ValidationState from "../../Forms/ValidationState";
import PrimaryButton from "../../Common/Buttons/PrimaryButton";
import { passwordSchema } from "../schema";
import { passwordTextFieldClasses } from "../../../styles/mui/passwordTextField";
import { I18n } from "react-redux-i18n";
import * as styles from ".././authentication.module.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  selectResetPasswordLoading,
  selectResetPasswordSuccess,
} from "../../../redux/selectors/authSelectors";
import { resetPasswordSendRequest } from "../../../redux/actions/authActions";
import {
  commonFormBase,
  muiStylesAuthentication,
} from "../muiStylesAuthentication";
import ErrorOutlineOutlinedIcon from "@mui/icons-material/ErrorOutlineOutlined";
import clsx from "clsx";
import { handleNavigateToLogin } from "../../../utils/paths";
import { useIsCapsLockOn } from "../../../utils/hooks";

interface Props {
  token: string | null;
  userId?: string | null;
}

const ResetPasswordComponent: FC<Props> = ({ token, userId }) => {
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const capsLock = useIsCapsLockOn();
  const requestPending = useSelector(selectResetPasswordLoading);
  const requestSucceeded = useSelector(selectResetPasswordSuccess);

  const {
    register,
    handleSubmit,
    control,
    setError,
    formState: { errors, isValid },
  } = useForm<{ newPassword: string }>({
    mode: "all",
    defaultValues: {
      newPassword: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(passwordSchema),
    criteriaMode: "all",
  });

  const onSubmit = handleSubmit((data): void => {
    if (userId && token) {
      const dataToSend = {
        userId,
        token,
        newPassword: data.newPassword,
      };

      dispatch(resetPasswordSendRequest(dataToSend));
    }
  });

  useEffect(() => {
    setError("newPassword", { types: { min: "min", matches: "matches" } });
  }, []);

  return (
    <>
      {requestSucceeded ? (
        <div className={clsx(styles.loginForm, styles.loginFormSuccess)}>
          <h1 className={styles.header}>
            {I18n.t("ResetPassword.successTitle")}
          </h1>
          <div className={styles.formSuccessMessageContainer}>
            {I18n.t("ResetPassword.success")}
          </div>
          <PrimaryButton
            text={I18n.t("Buttons.backToLogin")}
            event={handleNavigateToLogin}
          />
        </div>
      ) : (
        <>
          <form onSubmit={onSubmit} className={styles.loginForm}>
            <h1 className={styles.header}>
              {I18n.t("ResetPassword.setNewPassword")}
            </h1>
            <div className={styles.formMessageContainer}>
              {I18n.t("ResetPassword.setNewPasswordInfo")}
            </div>
            <Controller
              name="newPassword"
              render={({ field }) => (
                <TextField
                  {...register("newPassword")}
                  autoComplete="off"
                  variant="outlined"
                  fullWidth
                  label={I18n.t("FormLabels.password")}
                  sx={muiStylesAuthentication.inputResetPassword}
                  type={showPassword ? "text" : "password"}
                  disabled={requestPending || requestSucceeded}
                  InputProps={{
                    sx: muiStylesAuthentication.inputResetPassword,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          sx={commonFormBase.passwordVisibility}
                          aria-label="toggle password visibility"
                          onClick={() => setShowPassword(!showPassword)}
                        >
                          {showPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  {...field}
                />
              )}
              control={control}
            />

            <div
              className={clsx(
                styles.loginMessagesContainer,
                styles.resetPassword
              )}
            >
              {capsLock && (
                <div className={styles.capslockContainer}>
                  <ErrorOutlineOutlinedIcon fontSize="small" />
                  <h3 className={styles.h3TypographyError}>{I18n.t("Login.warning")}</h3>
                </div>
              )}
              <p className={styles.validatorContainer}>
                <ValidationState
                  error={"min" in (errors?.newPassword?.types || {})}
                />
                <span className={styles.validatorText}>
                  {I18n.t("FormValidation.Password.length")}
                </span>
              </p>
              <p className={styles.validatorContainer}>
                <ValidationState
                  error={"matches" in (errors?.newPassword?.types || {})}
                />
                <span className={styles.validatorText}>
                  {I18n.t("FormValidation.Password.characters")}
                </span>
              </p>
            </div>

            <div className={styles.formButtonsContainer}>
              <PrimaryButton
                text={I18n.t("Buttons.savePassword")}
                disabled={!isValid || requestPending || requestSucceeded}
                isSubmit
              />
              {requestPending && (
                <CircularProgress
                  sx={passwordTextFieldClasses.base}
                  size={24}
                />
              )}
            </div>
          </form>
        </>
      )}
    </>
  );
};

export default ResetPasswordComponent;
