import * as React from "react";
import Seo from "../components/seo";
import { navigate, PageProps } from "gatsby";
import ResetPasswordComponent from "../components/Authentication/ResetPassword";
import { useEffect } from "react";
import { loginPath } from "../utils/auth";
import OnboardingLayout from "../components/Layout/OnboardingLayout";
import { ThemeProvider } from "@mui/material";
import { I18n } from "react-redux-i18n";
import { useCurrentTheme } from "../utils/hooks";
import { useSelector } from "react-redux";
import { selectLanguage } from "../redux/selectors/i18nSelectors";

const ResetPasswordPage = ({ location }: PageProps) => {
  const params = new URLSearchParams(location.search);
  const token = params.get("token");
  const userId = params.get("userId");
  const currentLanguage = useSelector(selectLanguage);
  const currentTheme = useCurrentTheme();
  const currLang = React.useRef(currentLanguage || "pl");

  useEffect(() => {
    if (currLang.current !== currentLanguage) {
      currLang.current = currentLanguage;
    }
  }, [currentLanguage]);

  useEffect(() => {
    if (!token || !userId) {
      void navigate(loginPath);
    }
  }, [token, userId]);

  return (
    <div style={{ height: "100vh" }} className={currentTheme.themeName}>
      <ThemeProvider theme={currentTheme.theme}>
        <Seo title={I18n.t("Common.seo.setNewPassword")} />
        <OnboardingLayout>
          <ResetPasswordComponent token={token} userId={userId} />
        </OnboardingLayout>
      </ThemeProvider>
    </div>
  );
};

export default ResetPasswordPage;
